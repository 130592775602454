<template>
    <div class="search-summary clearfix" v-if="summaryVisible">
        <span class="summary-title">您的搜索结果数：</span>
        <el-tag
            closable
            effect="plain"
            class="search-param-item"
            v-for="(item, index) in items"
            :key="index"
            @close="remove(item)"
        >   
            <span :title="item.text">{{handleText(item)}}</span>
        </el-tag>
            <!-- <span class="selected-option" v-for="item in items">
                <span v-text="handleText(item)" :title="item.text"></span>
                <i class="el-icon-close" @click="remove(item)"></i>
            </span> -->
        <span class="candidate-total">
            共 <span class="text-primary">{{total | currency('', 0)}}</span> 人
        </span>
        <span class="clear-search" @click="handleClearSearch">
            <i class="el-icon-delete"></i>
            清空搜索条件
        </span>
        <!-- <div class="pull-left">
            <span class="summary-title">您的搜索结果数：</span>
                <span class="selected-option" v-for="item in items">
                    <span v-text="handleText(item)" :title="item.text"></span>
                    <i class="el-icon-close" @click="remove(item)"></i>
                </span>
            共 <span class="text-primary">{{total | currency('',0)}}</span> 人
            <span class="clear-search text-primary" @click="handleClearSearch">
                <i class="el-icon-delete"></i>
                清空搜索条件
            </span>
        </div> -->
        <!-- <div class="pull-right cloud-search-info" v-show="similarNum > 0">
            您有云端类似简历<span>{{similarNum}}</span>份
            <a :href="cloudLink" target="_blank" class="cloud-link">
                立即获取
                <font-icon class="icon-right-arrow" href="#icon-double-right-arrow"></font-icon>
            </a>
        </div> -->
    </div>
    <div class="search-param-container" v-else></div>
</template>
<script>

export default {
    components:{
    },
    data(){
        return{
            items:[],
            total:'',
            similarNum:0,
            cloudLink:'',

        }
    },
    computed:{
        summaryVisible(){
            return this.items.length>0;
        }
    },
    filters:{
        toThousands(num){
            return num.toLocaleString();
        }
    },

    methods: {
        handleClearSearch(isSearch = true) {
            this.items = [];
            this.$emit('clear-search', isSearch);
        },
        remove(item) {
            let idx = this.items.indexOf(item);
            if(~idx) {
                this.items.splice(idx, 1);
                this.$emit('search-summary-remove',item)
            }
        },

        loadData(data) {
            this.total=data.total;
        },

        loadCloudData(data, param) {
            this.similarNum=data.actualCount;
            let query = $.param(param, true);
            query = query.replace(/\+/g,'%20');
            this.cloudLink='/CloudCandidates#/pager/?' + query;
        },

        handleText(vm){
            if(vm.description){
                return vm.description + '：' + vm.text;
            }else{
                return vm.text;
            }
        },
    },
};

</script>
<style lang="scss" scoped>
.search-param-container {
    min-height: 10px;
    background: #f6f6f6;
}
.search-summary {
    display: flex;
    align-items: center;
    height: 52px;
    background: #f6f6f6;

    .summary-title {
        // color: #333;
    }

    .search-param-item.el-tag {
        position: relative;
        max-width: 500px;
        height: 30px;
        line-height: 28px;
        margin: 5px 10px 5px 0;
        padding-right: 30px;
        font-size: 14px;
        border-radius: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        /deep/ .el-icon-close {
            position: absolute;
            top: 50%;
            right: 5px;
            margin-top: -8px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .candidate-total {
        display: inline-block;
        margin-left: 4px;
    }

    .selected-option {
        position: relative;
        display: inline-block;
        margin-right: 15px;
        border: 1px solid #d3d3d3;
        background-color: #fff;
        color: $primary;
        line-height: 24px;
        padding: 0 23px 0 7px;
        margin-bottom: 8px;
        vertical-align: top;

        > span {
            font-size: 12px;
            display: block;
            max-width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .option-remove {
            position: absolute;
            width: 23px;
            right: 0;
            top: 0;
            text-align: center;
            cursor: pointer;

            .doll-container {
                display: block;
            }

            .doll {
                font-size: 12px;
                transform: scale(.8);
            }
        }

        .el-icon-close {
            position: absolute;
            width: 23px;
            right: 0;
            top: 0;
            bottom: 0;
            line-height: 26px;
            text-align: center;
            color: #ccc;
            cursor: pointer;
        }
    }

    .clear-search {
        display: inline-flex;
        align-items: center;
        margin-left: 36px;
        cursor: pointer;

        .el-icon-delete {
            font-size: 18px;
            margin-right: 6px;
        }

        .font-icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }

        // &:hover {
        //     color: $primary;
        // }
    }

    .cloud-link {
        margin-left: 20px;
        color: $primary;

        .icon-right-arrow {
            width: 10px;
            height: 10px;
            fill: $primary;
        }
    }

    .cloud-search-info {
        margin-bottom: 8px;
        height: 26px;
    }
}
/deep/ .option-remove.remove-size .icon-close{
    font-size: 12px;
    transform: scale(.8);
}
</style>
