var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.summaryVisible
    ? _c(
        "div",
        { staticClass: "search-summary clearfix" },
        [
          _c("span", { staticClass: "summary-title" }, [
            _vm._v("您的搜索结果数："),
          ]),
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticClass: "search-param-item",
                attrs: { closable: "", effect: "plain" },
                on: {
                  close: function ($event) {
                    return _vm.remove(item)
                  },
                },
              },
              [
                _c("span", { attrs: { title: item.text } }, [
                  _vm._v(_vm._s(_vm.handleText(item))),
                ]),
              ]
            )
          }),
          _c("span", { staticClass: "candidate-total" }, [
            _vm._v("\n        共 "),
            _c("span", { staticClass: "text-primary" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.total, "", 0))),
            ]),
            _vm._v(" 人\n    "),
          ]),
          _c(
            "span",
            {
              staticClass: "clear-search",
              on: { click: _vm.handleClearSearch },
            },
            [
              _c("i", { staticClass: "el-icon-delete" }),
              _vm._v("\n        清空搜索条件\n    "),
            ]
          ),
        ],
        2
      )
    : _c("div", { staticClass: "search-param-container" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }